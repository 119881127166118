<template>
    <paramBox :dataList='dataList' @onclose='colseClick'>
        <ul class="content_box">
            <template v-if="imgList.length">
                    <li v-for="(item,i) of imgList" :key="i" class="cp">
                    <el-image 
                            style="width: 100%; height: 100%"
                            :src="item" 
                            :preview-src-list="bigImg(item)">
                        </el-image> 
                    </li>
                    <template v-for="(ele,index) of supplyBox" >
                        <li class="supplyBox" :key="index+'qwe'"></li>
                    </template>
            </template>
            <template v-else>
                    <li class="not_data_box">
                        暂无设备
                    </li>
            </template>
                
        </ul>
    </paramBox>
 
</template>

<script>
    import { completionRow } from '@/libs/util'
    import paramBox from '@/components/localComponent/paramBox.vue'
    export default {
        name:"imgsParam",
        components:{
            paramBox
        },
        props:{
            dataList:Object
        },
        data(){
            return{
               supplyBox:[],
            }
        },
        computed:{
           bigImg(){
              return item => [item]
           },
           imgList(){
               let info = this.dataList
               if(info && info.data){
                   let imgInfo = JSON.parse(info.data)
                   
                   if(imgInfo && imgInfo.length){
                      let supplyArr = completionRow(imgInfo,3)
                      this.supplyBox = supplyArr
                      return imgInfo
                   }
               }
               return []
           },
          

        },
        methods:{
            colseClick(){
                this.$emit('onclose')
            }
        }
    }
</script>

<style lang="scss" scoped>
.content_box{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    margin-top: 10px;
    & > li {
        width: 30%;
        height: 80px;
        
        // & > img{
        //     width: 100%;
        //     height: 100%;
        //     background: chocolate;
        //     border-radius: 5px;
        // }
        
    }
    & > li:nth-child(n + 4){
        margin-top: 10px;
    }
    .supplyBox{
        background-color: transparent !important;
    }
}
</style>
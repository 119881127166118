<template>
  <paramBox :dataList='dataList' @onclose='colseClick'>
      <ul class="content_box">
         <template v-if="paramList.length">
                <li v-for="(item,i) of paramList" :key="i" class="ctr_box_border">
                  <p>{{ item.name }}</p>   
                  <p class="baseColor">{{ paramValueHandle(item) }}</p>   
                </li>
                <template v-for="(ele,index) of supplyBox" >
                    <li class="supplyBox" :key="index+'qwe'"></li>
                </template>
            </template>
            <template v-else>
                <li class="not_data_box">
                    <span class="iconfont icon-zanwushuju"></span>
                    <p>暂无数据</p>
                </li>
            </template>
        </ul>
   </paramBox>
 
</template>

<script> 
    import paramBox from '@/components/localComponent/paramBox.vue'
    import { completionRow } from '@/libs/util'
    export default {
        name:"collectionParam",
        components:{
            paramBox
        },
        props:{
            dataList:Object
        },
        data(){
            return{
               supplyBox:[],
            }
        },
        computed:{
            paramValueHandle(){
                 return item => {
                   if(!item.latestValue && item.latestValue !== 0) return '无数据'
                      if(item.name === '风向'){
                     
                        let valArr = [
                            {
                                max:112.5,
                                min:67.5,
                                name: '北风'
                            },
                            {
                                max:157.5,
                                min:112.5,
                                name: '西北风'
                            },
                            {
                                max:202.5,
                                min:157.5,
                                name: '西风'
                            },
                            {
                                max:247.5,
                                min:202.5,
                                name: '西南风'
                            },
                            {
                                max:292.5,
                                min:247.5,
                                name: '南风'
                            },
                            {
                                max:337.5,
                                min:292.5,
                                name: '东南风'
                            },
                            {
                                max:22.5,
                                min:337.5,
                                name: '东风'
                            },
                            {
                                max:67.5,
                                min:22.5,
                                name: '东北风'
                            }
                        ]
                        let val = parseInt(item.latestValue)
                        let resultName = '无风'
                        valArr.some(ele => {
                            
                            if(val > 337.5 || val <= 22.5){
                                resultName= '东风'
                                return true
                            } 
                            if(val> ele.min && val<= ele.max){
                                resultName= ele.name
                                return true
                            }
                        })
                        return resultName
                   
                      }else {
                          return item.latestValue + item.enUnit
                      }
                 }
            },
            paramList(){
               let info = this.dataList
               if(info && info.data){
                   let paramInfo = JSON.parse(info.data)
                   
                   if(paramInfo && paramInfo.length){
                      let supplyArr = completionRow(paramInfo,3)
                      this.supplyBox = supplyArr
                      return paramInfo
                   }
               }
               return []
            },
           
        },
        methods:{
            colseClick(){
                this.$emit('onclose')
            },

        }
    }
</script>

<style lang="scss" scoped>
.content_box{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: flex-start;
        margin-top: 10px;
        & > li {
            width: 30%;
            height: 54px;
            // background: red;
            border-radius: 5px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 14px;
            & > p:last-child{
                font-size: 16px;
            }
        }
        & > li:nth-child(n + 4){
            margin-top: 10px;
        }
        .supplyBox{
            background-color: transparent !important;
        }

        .not_data_box{
            width: 100%;
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #999;
            font-size: 14px;
            & > span {
                font-size: 40px;

            }
        }
}
</style>
<template>
    <paramBox :dataList='dataList' @onclose='colseClick'>
        <div class="content_box"> 
          <video autoplay controls width="100%" height="100%" ref="videodome" style= "object-fit: fill"></video>
       </div>
    </paramBox>

</template>

<script>
    import paramBox from '@/components/localComponent/paramBox.vue'
    let Hls = require('hls.js')
    export default {
        name:"videoParam",
        props:{
            dataList:Object
        },
        components:{
            paramBox
        },
        data(){
            return{
               flvPlayer: null,
            }
        },
        created(){
           this.$nextTick(()=> {
                this.createVideo()
           })  
        },
        computed:{
           videoHlsUrl(){
               let info = this.dataList
               if(info && info.data){
                   let videoUrlInfo= JSON.parse(info.data)
                   return videoUrlInfo && videoUrlInfo.hls ? videoUrlInfo.hls : ''
               }
               return ''
              
           },
           videoFlvUrl(){
               let info = this.dataList
               if(info && info.data){
                   let videoUrlInfo= JSON.parse(info.data)
                   return videoUrlInfo && videoUrlInfo.flv? videoUrlInfo.flv: ''
               }
               return ''
              
           },
           

        },
        beforeDestroy(){
          if(this.flvPlayer) 
           this.flvPlayer.destroy()
        
        },
        methods:{
          createVideo(){
                if (this.$flv.isSupported())this.$nextTick(()=> this.createHls()) 
          },
            // 加载hls视频流
          createHls(){
     
              let videoElement = this.$refs.videodome        
              let hls = new Hls();
              hls.loadSource(this.videoHlsUrl); //
              hls.attachMedia(videoElement);
              hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    videoElement.play();
              });
              this.flvPlayer = hls
              
              
          },   
          createFlv(){
              
                let videoElement = this.$refs.videodome 
               
                let player = this.$flv.createPlayer({
                    type: 'flv',
                    url: this.videoFlvUrl
                });
            
                player.attachMediaElement(videoElement);
                player.load();
                player.play();
                this.flvPlayer = player

          },
          colseClick(){
            this.$emit('onclose')
          }
        }
    }
</script>

<style lang="scss" scoped>
.content_box{
       width: 100%;
       height: 240px;
       padding: 10px 0 5px 0;
       box-sizing: border-box; 
}
</style>
<template>
  <paramBox :dataList='dataList' @onclose='colseClick' min-height="80px">
      <ul class="content_box">
         <template v-if="paramInfo && paramInfo.length">
                <li v-for="(item,i) of paramInfo" :key="i" class="ctr_box_border">
                  <p>{{ item.name }}</p>   
                  <p class="baseColor">{{ item.val }}头</p>   
                </li>
             
            </template>
            <template v-else>
                <li class="not_data_box">
                    <span class="iconfont icon-zanwushuju"></span>
                    <p>暂无数据</p>
                </li>
            </template>
        </ul>
   </paramBox>
 
</template>

<script> 
    import paramBox from '@/components/localComponent/paramBox.vue'

    export default {
        name:"collectionParam",
        components:{
            paramBox
        },
        props:{
            dataList:Object
        },
        data(){
            return{
           
            }
        },
        computed:{
         
            paramInfo(){
               let info = this.dataList
               if(info && info.data){
                  
                   let param = JSON.parse(info.data)
                   let paramFirst =  param[0] 
                   return  [
                        {
                          name: '杀虫总量',
                          val: paramFirst.collect
                        },
                        {
                          name: '昨日杀虫',
                          val: paramFirst.dayTotalNum
                        },
                        {
                          name: '今日杀虫',
                          val: paramFirst.num
                        }
                        
                      ]
                   

               }
               return []
            },
           
        },
        methods:{
            colseClick(){
                this.$emit('onclose')
            },

        }
    }
</script>

<style lang="scss" scoped>
.content_box{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: flex-start;
        margin-top: 10px;
        & > li {
            width: 30%;
            height: 54px;
            // background: red;
            border-radius: 5px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 14px;
            & > p:last-child{
                font-size: 16px;
            }
        }
        & > li:nth-child(n + 4){
            margin-top: 10px;
        }
        .supplyBox{
            background-color: transparent !important;
        }

        .not_data_box{
            width: 100%;
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #999;
            font-size: 14px;
            & > span {
                font-size: 40px;

            }
        }
}
</style>
<template>
   <paramBox :dataList='dataList' @onclose='colseClick'>
     <ul class="content_box" >
            <template v-if="paramList.length">
                    <li v-for="(item,i) of paramList" :key="item.id" :class="isBg(i)">
                        <div>
                            <template v-if="item.channelStatus == 1 && item.workIcon">
                                <img :src="gifUrl(item.workIcon)" alt="">
                            </template>
                            <template v-else>
                                <span :class="['ctlIcon', item.isReversible && item.channelStatus == 0? 'baseColor' : '',item.icon] "></span>
                            </template>
                            <span class="devName_box">{{ item.name }}</span>
                        </div>
                        <!--  -->
                        <el-radio-group v-model="valueData[`ctl${item.id}`]" @change="((val) => {changeRadio(val,item)})">
                            <template v-if="item.isReversible">
                                <el-radio :label="2">关</el-radio>
                                <el-radio :label="1">开</el-radio>
                                <el-radio :label="0">暂停</el-radio>
                            </template>
                            <template v-else>
                                <el-radio :label="0">关</el-radio>
                                <el-radio :label="1">开</el-radio>
                            </template>
                            
                        </el-radio-group>
                       

                    </li>
            </template>
            <template v-else>
                    <li class="not_data_box">
                        暂无设备
                    </li>
            </template>
                
     </ul>
     <pwdForm
        :isShow.sync='isPwdShow'
        :ctlData='ctlData'
        @onOk= 'getPwd'
     ></pwdForm>
   </paramBox>
</template>

<script>
    import paramBox from '@/components/localComponent/paramBox.vue'
    import pwdForm from '@/components/localComponent/pwdForm'
    import { imgAddHost } from '@/libs/util'
    import ctrPwdMixin from '@/mixins/ctrPwd'
    export default {
        name: 'ctlParam',
        mixins: [ctrPwdMixin],
        components:{
            paramBox,
            pwdForm
        },
        props:{
            dataList:{
                type: Object,
            }
        },
        watch:{
           dataList:{
               handler(){
                 this.paramList = this.hanldeData()
               },
               immediate: true,
               deep: true
           },
           isPwdShow:{
               handler(val){
                  let item = this.activeDevInfo
                  if(!val && item){
                      let result = item.channelStatus < 3 ? item.channelStatus : item.isReversible? 2 : 0
                      this.$set(this.valueData,`ctl${item.id}`,result)
                  }
               }
           }
        },
        data(){
           return {
               valueData: {},
               ctlData: null,
               isPwdShow: false,
               activeDevInfo: null,
               paramList: []
           }
        },
        computed:{
            isBg(){
               return i => {
                   return i%2 !== 0 ? 'base-bg-opacity2': ''
               }
            },
            gifUrl(){
              return item => {
                 return  imgAddHost(item)
               }
            },
           
        },
        methods:{
            colseClick(){
               this.$emit('onclose')
            },
             // 设置密码
            getPwd(data){
                this.reqIotDeviceCtrlChannel(data)
            },
            isNeedPwd(){ 
              let { isSetPwd, devId } = this.dataList
      
              let result = false
              if( isSetPwd ){
                  result = this.isPwdPass(devId)  // 验证设备密码是否存在或过期
                  result = !result   
              }
              return result
            },
            changeRadio(val,item){
                let data = {
                        id: item.devId,
                        actuatorId: item.id,
                        devCode: item.devCode,
                        no: item.channelNo,
                        reversalNo: item.reverseChannelNo,
                        stopNo: item.closeChannelNo,
                        openType: val,
                }
                this.activeDevInfo = item
                if(this.isNeedPwd()){
                        this.ctlData= data
                        this.isPwdShow = true   
                }else{
                    let pwd = this.getPwdVal(item.devId)
                    if(pwd) data.ctrlPwd = pwd
                    this.reqIotDeviceCtrlChannel(data)
                }
            },
            // 设备控制
            async reqIotDeviceCtrlChannel(data){
                try{
                   const res = await this.$api.IotDeviceCtrlChannel(data)
                   if(res.hasOwnProperty('errcode') && res.errcode == 0){
                          this.addLocaCtrPwd(this.dataList.devId,data.ctrlPwd)
                   }else{
                      
                      this.resetData()
                   }      
                }catch{
                   
                    this.resetData()
                }
                
                
               
            },
            // 控制失败，还原按钮
            resetData(){
                this.$api.IotDeviceControlList({
                    devId: this.dataList.devId
                }).then(res => {
            
                    if(res && res.length){
                          res.some(item => {
                              if(this.activeDevInfo.id == item.id){
                                   let result = item.channelStatus < 3 ? item.channelStatus : item.isReversible? 2 : 0
                                   this.$set(this.valueData,`ctl${item.id}`,result)
                                   return true
                              } 
                          })
                    }
                })
            },
            hanldeData(){
               let info = this.dataList
               if(info && info.data){
                   let paramInfo = JSON.parse(info.data)
                   if(paramInfo && paramInfo.length){
                       paramInfo.map(item =>{
                          let result = item.channelStatus < 3 ? item.channelStatus : item.isReversible? 2 : 0
                          this.$set(this.valueData,`ctl${item.id}`,result)
                          
                       })
                      return paramInfo
                   }
               }
               return []
            }
        
        }
    }
</script>

<style lang="scss" scoped>
.content_box{
    width: 100%;
    margin-top: 10px;
    padding-right: 5px;
    box-sizing: border-box;
    & > li {
        width: 100%;
        height: 42px;
        padding-left: 14px;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        align-items: center;
        
        & > div:first-child{
            width: 120px;
            display: flex;
            align-items: center;
            .ctlIcon{
                font-size: 20px;
                color: #999;
            }
            & > img {
                width: 20px;
            }
            .devName_box{
                margin-left: 8px;
            }
        }
    }
 
}
</style>
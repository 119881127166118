import videoParam from './videoParam.vue'
import imgsParam from './imgsParam.vue'
import collectionParam from './collectionParam.vue'
import ctlParam from './ctlParam.vue'
import insectParam from './insectParam.vue'
import insectLamp from './insectLamp.vue'
export default {
    videoParam,
    imgsParam,
    collectionParam,
    ctlParam,
    insectParam,
    insectLamp
}
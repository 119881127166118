import Vue from 'vue';
import windowInfo from './windowInfo.vue'
let infoContent = Vue.extend(windowInfo)
function isFunction(val) {
    return typeof (val) === "function";
}
export default function(data){
    let instance = new infoContent().$mount()
    let vm = this
    instance.onParam = (positionInfo) => {
          if(vm){
              
            if(isFunction(vm.$options.onParam)) vm.$options.onParam.call(vm,positionInfo)  
          }   
    }

    instance.$props.devId = data.devId  
    return instance.$el
}
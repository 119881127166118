<template>
   <div :class="['circle_main_box', isActive? 'activeCircle': '']">
        <div class="btn trigger trigger_butbox"  @click="open">
        </div>
        <div class="icons">
            <div class="rotater" v-for="(item,i) of infoList" :key="item.id">
                  <el-popover
                    v-model="item.visible"
                    :placement="hanledPosition(i)"
                    :popper-class="computLRClass(i)"
                    trigger="click"
                    >
                        <component @onclose='closePopover(item)' v-if="item.visible" :is="paramShowType[item.showType]" :dataList= 'paramData'></component>
                        <div :class="['btn',statusStyle(item.onlineStatus),'btn-icon', devActiveId === item.id?'devIconActive':'']" slot="reference" @click="devChange(item)">
                                <i :class="['iconfont',item.iconCls]"></i>
                        </div>
                    </el-popover>
                
            </div>
        </div>
    </div>
</template>

<script>
    import paramDome from '@/views/pages/indexHome/components/paramDome'
    import config from '@/config'
    export default {
        components:{
           ...paramDome
        },
        props:{
           devList:{
               type: Array,
               default: () => []
           },
           active:{
               type: Boolean,
               default: true
           }
        },
        data() {
            return {
               isActive: false,
               devActiveId: '',
               infoList: [],
               paramData: null,
               paramShowType: config.paramShowType,
               positionInfo:{
                   left: [0,1,6,7],
                   right: [2,3,4,5]
               }
               
            }
        },
        watch:{
           active:{
              handler(val){
                 this.isActive = val
              }
           },
           devList:{
               handler(val){
                   this.infoList = val.length > 8 ? val.slice(0,8) : val
               },
               immediate: true
           }
        },
        computed:{
            hanledPosition(){
                return i => {
                    if(this.positionInfo.left.includes(i)){
                        return 'left'
                    }else{
                        return 'right'
                    }
                }
            },
            computLRClass(){
                return i => {
                   let result = this.hanledPosition(i)
                   return result == 'left' ? 'formLeftPopper': 'formRightPopper'
                }
           },
           statusStyle(){
               return status => status && status == config.devStatus.online ?
                                'onlineBut': status == '2' ?  
                                'standbyColor' : 'offLineColor'      
           }
        },
        created(){
            // this.infoList = this.devList
            this.$nextTick(()=>{
                setTimeout(() => {
                   this.isActive = this.active 
                },300)
                 
            })
            
        },
        methods:{
            open() {
               this.isActive =!this.isActive
               this.devActiveId = ''
            },
            devChange(item){
               
               this.devActiveId = item.id
               this.paramData = item
            //    item.visible = true
               
            },
            closePopover(item){
                item.visible= false
                this.devActiveId = ''
            }
        }
    }
</script>

<style lang="scss" scoped>
.circle_main_box,
.circle_main_box .btn .iconfont
 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.circle_main_box {
    width: 4em;
    height: 4em;
    .icons{
        .rotater{
            .btn{
                 background: #fff;
            }
        }
    }
}
.onlineBut{
   
    color: #00C395;
}

.trigger_butbox{
   background: url('../../assets/imgs/index/indexDefault/circle_map.png') no-repeat;
   background-size: 100% 100%;
   animation:turn 3s linear infinite;
}

.circle_main_box .btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0;
    z-index: -10;
    cursor: pointer;
    transition: opacity 1s, z-index 0.3s, transform 1s;
    transform: translateX(0);
}
.circle_main_box .btn .iconfont {
    font-size: 36px;
    transition: color 0.3s;
}
// .circle_main_box .btn:hover .iconfont {
    
// }
.circle_main_box .btn.trigger {
    opacity: 1;
    z-index: 100;
    cursor: pointer;
    transition: transform 0.3s;
}
.circle_main_box .btn.trigger:hover {
    transform: scale(1.2);
}
.circle_main_box .devIconActive{
    background-color: #00C395 !important;
    color: #fff !important;
    transition: background-color 0.3s;
    
}
.circle_main_box .rotater {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
}
.circle_main_box .rotater a {
    color: #fff;
    display: block;
}
.circle_main_box.activeCircle .btn-icon {
    opacity: 1;
    z-index: 50;
}
.rotater:nth-child(1) {
    transform: rotate(-90deg);
}
.circle_main_box.activeCircle .rotater:nth-child(1) .btn-icon {
    transform: translateY(-8em) rotate(90deg);
}
.rotater:nth-child(2) {
    transform: rotate(-45deg);
}
.circle_main_box.activeCircle .rotater:nth-child(2) .btn-icon {
    transform: translateY(-8em) rotate(45deg);
}
.rotater:nth-child(3) {
    transform: rotate(0deg);
}
.circle_main_box.activeCircle .rotater:nth-child(3) .btn-icon {
    transform: translateY(-8em) rotate(0deg);
}
.rotater:nth-child(4) {
    transform: rotate(45deg);
}
.circle_main_box.activeCircle .rotater:nth-child(4) .btn-icon {
    transform: translateY(-8em) rotate(-45deg);
}
.rotater:nth-child(5) {
    transform: rotate(90deg);
}
.circle_main_box.activeCircle .rotater:nth-child(5) .btn-icon {
    transform: translateY(-8em) rotate(-90deg);
}
.rotater:nth-child(6) {
    transform: rotate(135deg);
}
.circle_main_box.activeCircle .rotater:nth-child(6) .btn-icon {
    transform: translateY(-8em) rotate(-135deg);
}
.rotater:nth-child(7) {
    transform: rotate(180deg);
}
.circle_main_box.activeCircle .rotater:nth-child(7) .btn-icon {
    transform: translateY(-8em) rotate(-180deg);
}
.rotater:nth-child(8) {
    transform: rotate(225deg);
}
.circle_main_box.activeCircle .rotater:nth-child(8) .btn-icon {
    transform: translateY(-8em) rotate(-225deg);
}

</style>
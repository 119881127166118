<template>
    <!-- <div class="pwdForm_box"> </div> -->
    <div class="dialog_box wh100" v-if="isShow">
        <div class="comments_content_box boxBg">
            <div class="title_box">
                <p>
                    <span class="iconfont icon-title baseColor"></span>
                    <span>控制密码</span>
                </p>
                <span class="delIcon iconfont icon-guanbi cp" @click="resetForm('ruleForm')"></span>
            </div>
            <div class="textArea_box">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" size="small" label-width="12px" class="demo-ruleForm">
                    <el-form-item prop="pwd" label=" ">
                        <el-input v-model="ruleForm.pwd" placeholder="输入控制密码" type="password"></el-input>
                    </el-form-item>
                    <el-form-item class="but_box">
                        <el-button class="butbox" size="mini" @click="resetForm('ruleForm')" round>取消</el-button>
                        <el-button class="butbox" type="primary" size="mini" @click="submitForm('ruleForm')" round>确定</el-button>
                    </el-form-item>
                </el-form>
            </div>
       
        </div>
    </div>
   
</template>

<script>
    export default {
        props:{
        
            isShow: {
                type: Boolean,
                default: false
            },
            ctlData: {
                type: Object,
                default:()=> { return {}}
            }
        },
        data(){
            return{
                ruleForm:{
                    pwd: ''
                },
                rules:{
                    pwd: [
                        { required: true, message: '请输入控制密码', trigger: 'blur' },
                    ],
                },
                closePage: false
            }
        },
        methods:{
           submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                       this.$emit('update:isShow',false)
                       let data = Object.assign({ ctrlPwd: this.ruleForm.pwd },this.ctlData)
                       this.$emit('onOk',data)
                       this.$refs[formName].resetFields();
                    } else {
                  
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.$emit('update:isShow',false)
            }
        }
    }
</script>

<style lang="scss" scoped>

  .dialog_box{
       position: absolute;
       left: 0;
       top: 0;
       z-index: 10;
       background: rgba(0,0,0,0.3);
      .comments_content_box{
          width: 70%;
        //   height: 250px;
          border-radius: 8px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          .title_box{
              width: 100%;
              height: 40px;
              box-sizing: border-box;
              padding: 0 10px;
              border-bottom: 2px solid #2C3E4A;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .delIcon{
                  font-size: 12px;
              }
              & > p {
                   display: flex;
                  align-items: center;
                  & > span:first-child{
                      margin-right: 5px;
                      font-size: 18px;
                  }
              }
              
          }
          .textArea_box{
               width: 85%;
               margin:  10px auto 0 auto ;
          }
          .but_box{
              text-align: right;
              margin-bottom: 10px;
            //   padding-right: 30px;
          }
      }  
   } 

</style>
<template>
    <div class="hn_indexHome_page_box">
        <div class="top_box">
            <div class="left_box">
                <div class="weather_box boxBg">
                   <p>
                       <span>{{ admProvince }}</span>
                       <span>-</span>
                       <span>{{ country }}</span>
                       <span>{{ dateHandle }}</span>
                       <span>{{ weekHandle }}</span>
                   </p>
                   <div class="weather_navList_box">
                       <span>{{ city  }}</span>
                         
                       
                       <ul class="boxsubBg">
                           <li 
                           :class="[weatherActive == item.code? 'active':'', 'weather-cp']"
                           v-for="item of weatherTypeList"
                           :key="item.code"
                           @click="weatherTypeChange(item.code)"
                           >{{ item.name }}</li>
                       </ul>
                   </div>
                   <div class="swiper-container devNub-swiper">
                        <div class="swiper-wrapper">
                        
                                <div class="swiper-slide slide_dome1">
                                   <template v-if="nowWeatherInfo">
                                        <div>
                                            <div class="img_box">
                                                 <img :src="hanldeIcon" alt="">
                                            </div>
                                            
                                            <div>
                                                <p>{{ nowWeatherInfo.temp }}℃</p>
                                                <p>
                                                    <span>{{ nowWeatherInfo.text }}</span>
                                                    <span class="active">AQI{{ airInfo.category}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <p class="sinleline">{{ airInfo.text }}</p>
                                        <ul class="boxsubBg">
                                            <li>
                                                <img :src="weaDataIcon[1]">
                                                <p>
                                                    <span>{{ nowWeatherInfo.vis}}km</span>
                                                    <span>能见度</span>
                                                </p>
                                            </li>
                                            <li>
                                                <img :src="weaDataIcon[0]">
                                                <p>
                                                    <span>{{ nowWeatherInfo.windScale }}级</span>
                                                    <span>{{ nowWeatherInfo.windDir }}</span>
                                                </p>
                                            </li>
                                            <li>
                                                <img :src="weaDataIcon[2]">
                                                <p>
                                                    <span>{{ nowWeatherInfo.humidity }}%</span>
                                                    <span>相对湿度</span>
                                                </p>
                                            </li>
                                            <li>
                                                <img :src="weaDataIcon[3]">
                                                <p>
                                                    <span>{{ nowWeatherInfo.precip }}mm</span>
                                                    <span>降水量</span>
                                                </p>
                                            </li>
                                        </ul>
                                   </template>
                                   <template v-else>
                                         <el-empty :image-size="100" :image='noDataUrl'></el-empty>   
                                   </template>
                                </div>
                                <div class="swiper-slide slide_dome2">
                                    <template v-if="sevenWeatherList.length">
                                        <ul>
                                            <li v-for="(item,i) of sevenWeatherList" :key="item.fxDate">
                                                <p>{{ weekDay(item.fxDate,i) }}</p>
                                                <p>{{ dateDay(item.fxDate) }}</p>
                                                <p :class="`qi-${item.iconDay}`"></p>
                                                <p>{{ item.textDay }}</p>
                                                <p>{{ maxMinTemp(item) }}</p>
                                                <p class="active">{{ item.windDirDay }}</p>
                                            </li>
                                        </ul> 
                                   </template>
                                   <template v-else>
                                         <el-empty :image-size="100" :image='noDataUrl'></el-empty>   
                                   </template>
                                </div>  
                                <div class="swiper-slide slide_dome3">
                                  
                                       <template v-if="warnWeatherList">
                                            <div class="warnWeather_box wh100">
                                                <div>
                                                    <span :style="warnColor" class="iconfont icon-yujing"></span>
                                                    <div>
                                                        <p>{{ warnLevel }}</p>
                                                        <p>
                                                            <span>{{ warnWeatherList.typeName }}预警</span>
                                                            <span class="active">预警中</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span>发布时间：</span>
                                                    <span>{{ warnWeatherList.pubTime}}</span>
                                                </div>
                                                <div class="boxsubBg">
                                                    <span class="iconfont icon-yujing" :style="warnColor"></span>
                                                    <div style="width:100%;overflow:hidden">
                                                        <p class="sinleline">{{warnWeatherList.title}}</p>
                                                        <!-- sinleline -->
                                                        <p class="textAnimate">{{ warnWeatherList.text}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                       </template>  
                                       <template v-else>
                                         <el-empty :image-size="100" :image='noDataUrl'></el-empty>   
                                       </template>
                                </div>  
                                
                        </div>
                   </div>
            
                </div>
                <div>
                    <box title="基地概况">
                        <div class="wh100 baseInfoPosition">
                            <div class="index-echarts-bg-image"></div>
                            <div class="basetotleArea_box">
                                <p>总面积</p>
                                <p class="baseColor">{{ totalArea }}亩</p>
                            </div>
                            <div class="wh100" ref="pieDome"></div>
                        </div>
                    </box>
                </div>
            </div>
            <div class="right_box boxBg">
                <div id="container" class="wh100"></div>
            </div>
        </div>
        <div class="bottom_box">
            <div class="subItem_content_box">
                <box title="设备接入">
                    <template v-if="devOnlineInfo.length">
                        <div class="devList_warp_box wh100"> 
                            <ul class="devInfoList_box boxScroll wh100">
                                <li v-for="item of devOnlineInfo" :key="item.devTypeId">
                                <span class="dev-line-gradient"></span>
                                <span class="base-bg-opacity5">{{ item.devTypeName }}</span>
                                <div>
                                        <p><span class="active"></span> 在线 <span class="baseColor">{{ item.onlineNum }}</span>台</p>
                                        <p><span></span> 待机 <span>{{ item.standingByNum }}</span>台</p>
                                        <p><span></span> 离线 <span>{{ item.offlineNum }}</span>台</p>
                                </div>
                                </li>
                            
                            </ul>
                            <div class="dev-line-gradient-list"></div>
                        </div>
                    </template>
                    <template v-else>
                        <el-empty :image-size="80"></el-empty>   
                    </template>
                </box>
            </div>
            <div class="subItem_content_box">
                
                <box title="设备快照">
                    <div slot="tools" class="paramName_box" v-if="snapshotsDevList.length">
                      {{ snapshotsDevName }}
                    </div>
                    <template v-if="snapshotsDevList.length">
                     <div class="swiper-container devSnapahot-swiper" id="autoStopPlay" style="max-width: 470px;height: 100%">
                            <div class="swiper-wrapper">
                            
                                    <div class="swiper-slide dev_param_box" v-for="item of snapshotsDevList" :key="item.devId">
                                        <!-- <div class=""</div>> -->
                                            <div class="left_box index-param-bg-image">
                                                <p><span>{{ item.uploadCount }}</span>次</p>
                                                <p class="index-paramName-bg-image">数据上传次数</p>
                                            </div>
                                            <div class="right_box">
                                               
                                                 <el-popover
                                                       v-for="(val,i) of item.deviceSnapshots"
                                                       :key="i"
                                                        placement="top"
                                                        width="80"
                                                        trigger="hover"
                                                        popper-class= 'snapshots_popver_box'
                                                        :disabled='!!val.paramType'
                                                        class="snapshots_box"
                                                        >
                                                        <div class="snapshots_hover_box">
                                                           <p> <span class="active"></span></p>
                                                           <div >
                                                               <p>
                                                                   {{ val.paramName }}
                                                               </p>
                                                               <p class="baseColor">
                                                                    {{  paramValueHandle(val) + val.paramUnit }}
                                                               </p>
                                                              </div>
                                                        </div>
                                                        
                                                 
                                                       <div class="index-paramBox-bg-image snapshots_boxs wh100" slot="reference">
                                                            <span :class="['baseColor', 'iconfont', val.paramIcon]"></span>
                                                            <template v-if="!val.paramType">
                                                              <span>{{ paramValueHandle(val) }}</span>
                                                            </template>
                                                            <template v-else>
                                                                <svg class="font-icon font-icon-svg" aria-hidden="true">
                                                                    <use xlink:href="#icon-wushuju"></use>
                                                                </svg>
                                                            </template>
                                                       </div>
                                                 </el-popover>
                                                
                                            </div>
                                        
                                    </div>
                                
                            </div>
                     </div>
                   </template>
                   <template v-else>
                      <el-empty :image-size="80"></el-empty>   
                   </template>
                </box>
            </div>
            <div class="subItem_content_box">
                <box title="数据分析">
                    <template slot="tools">
                        <el-select v-model="devId" @change="devChange" class="devSelect_box selectDev" placeholder="请选择" size="mini">
                            <el-option
                            v-for="item in devSelectOption"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                         </el-select>
                    </template>
                    <template v-if="devSelectOption && devSelectOption.length">
                     <div class="data_analysis_box wh100">
                        <ul class="boxsubBg">
                           <li 
                           :class="[paramActive == item.code? 'active':'', 'weather-cp']"
                           v-for="item of paramTypeList"
                           :key="item.code"
                           @click="paramTypeChange(item.code)"
                           >{{ item.name }}</li>
                       </ul>
                       <div class="param_box_line_gradient"></div>
                       <div class="param_val_box" 
                           v-loading="changLoading"
                           element-loading-text="拼命加载中"
                           element-loading-spinner="el-icon-loading"
                           element-loading-background="rgba(40, 53, 63, 0.8)">
                         <template v-if="MaxMinParamList.length">
                           <ul class="boxScroll wh100">
                             
                               <li v-for="(item,index) of MaxMinParamList" :key="index">
                                   <!-- <template v-if="item.paramName != '风向'"></template> -->
                                    <div>
                                        <span class="index-paramData-bg-image"></span>
                                        <span :class="['baseColor', 'iconfont', item.icon] "></span>
                                    </div>
                                    <div>
                                        <p>{{ item.paramName }}</p>
                                        <div>
                                            <p>
                                                <span>最高：</span>
                                                <span class="maxColor">{{ (item.maxVal == null? '0':item.maxVal) + item.paramUnit }}</span>
                                            </p>
                                            <p>
                                                <span>最低：</span>
                                                <span class="baseColor">{{ (item.minVal == null? '0':item.minVal) + item.paramUnit }}</span>
                                            </p>
                                        </div>
                                    </div>
                          
                               </li>
                               
                           </ul>
                        </template>
                        <template v-else>
                            <el-empty :image-size="60"></el-empty>   
                        </template>
                       </div>

                     </div>
                    </template>
                    <template>
                        <el-empty :image-size="80"></el-empty> 
                    </template>
                </box>
            </div>
            <div class="subItem_content_box">
                <box title="智能告警" >
                    <div class="warn_box">
                      <el-table
                      class="waring_table"
                    :data="tableData"
                    :header-row-class-name="tableRowClassName"
                     height="100%"
                    :row-class-name= 'tableRow'
                    :row-style="{height: '28px'}"
                    :cell-style="{padding: '5px 0'}"
                    >
                        
                        <el-table-column
                            prop="alarmContent"
                            label="告警详情"
                            align="center"
                            :show-overflow-tooltip= 'true'
                        >
                        </el-table-column>
                        <el-table-column
                            prop="alarmTime"
                            label="告警日期"
                            align="center"
                            :formatter='waringTimeHanlde'
                            width="120px"
                        >
                        </el-table-column>
                        <template slot="empty">
                           <el-empty :image-size="70"></el-empty> 
                        </template>
                        <!-- <el-table-column label="操作" align="center"  width="120px">
                            <template slot-scope="scope">
                                <el-button
                                class="opira_but_box base-bg-opacity5"
                                size="mini"
                                @click="handleEdit(scope.$index, scope.row)">立即处理</el-button>
                            </template>
                        </el-table-column> -->
                      </el-table>
                    </div>
                </box>
            </div>
        </div>
        <HnSplitPane
            direction="row" 
            :min="15" 
            :max="80" 
            :triggerLength="20" 
            :paneLengthPercent.sync="paneLengthPercent" 
        ></HnSplitPane>
        
    </div>
</template>

<script>
    import box from './components/box'
    import { optionIndexPie } from '@/libs/echartsOption'
    import windowInfo from './components/windowInfo'
    import config from '@/config'
    import AMapLoader from '@amap/amap-jsapi-loader';
    import transform from '@/libs/transformMap.js'
    export default {
        components:{
           box
        },
        data() {
 
            return {
                changLoading: false,
                noDataUrl: require('@/assets/imgs/noData/no_data2.png'),
                totalArea: 0,
                devOnlineInfo: [],
                snapshotsDevList: [],
                snapshotsDevName: '',
                devSelectOption:[],
                devId:'',
                devCode: '',
                paramTypeList:[
                   {
                       name: '三个月',
                       code: 1
                   },
                    {
                       name: '半年内',
                       code: 2
                   },
                   {
                       name: '一年内',
                       code: 3
                   }
                ],
                paramActive: 1,
                MaxMinParamList: [],
                sevenWeatherList: [],
                warnWeatherList: null,
                //    以上为正式数据 
                paneLengthPercent: 80,
                weatherTypeList:[
                   {
                       name: '天气',
                       code: 0
                   },
                    {
                       name: '7天预报',
                       code: 1
                   },
                   {
                       name: '气象预警',
                       code: 2
                   }
               ],
               weaDataIcon: [
                   require('@/assets/weatherIcon/wind--day.png'),
                   require('@/assets/weatherIcon/vis--day.png'),
                   require('@/assets/weatherIcon/hum--day.png'),
                   require('@/assets/weatherIcon/pcpn--day.png'),
               ],
               weatherActive: 0,
               weatherSwiper: null,
               nowWeatherInfo: null,
               airInfo: null,
               admProvince: '',
               country:"中国",
               city:'',
               tableData: []
               
            }
        },
        created() {
            
           this.reqCompanyWeatherDetail()
           this.reqEntBaseSurveyList()
           this.reqIotDeviceAccessList()
           this.reqIotDeviceSnapshotList()
           this.reqIotDeviceDropDownList()
           this.reqLogIntelligentWarningList()
           
        },
        computed: {
            isMapLayer(){
              return this.$store.state.user.userInfo ?.mapLayer == 0 ? true : false
            },
            userInfo(){
                return this.$store.state.user.userInfo
            },
            dateDay() {
                return item => {
                    let date = new Date(item)
                    let month = date.getMonth() + 1,day = date.getDate()
                    return `${month}/${day}`

                }   
            },
            weekDay(){
                return (item,i) => {
                    if(i === 0){
                        return '今天'
                    }
                    let date = new Date(item)
                    const weeksList = [
                        '周日',
                        '周一',
                        '周二',
                        '周三',
                        '周四',
                        '周五',
                        '周六',
                    ]
                    let weekIndex = date.getDay()
                    return weeksList[weekIndex]

                } 
            },
            swiper() {
                return this.$refs.mySwiper.swiper
            },
            hanldeIcon(){
                if(this.nowWeatherInfo && this.nowWeatherInfo.icon){
                      return require(`@/assets/weatherIcon/${this.nowWeatherInfo.icon}.png`)
                } 
                return ''
            },
            weekHandle() {
                let mydate=new Date();
                let myddy=mydate.getDay();//获取存储当前日期
                let weekday=["星期日","星期一","星期二","星期三","星期四","星期五","星期六"];
                return weekday[myddy]
            },
            dateHandle() {
                let date = new Date();
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                if (month < 10) {
                    month = "0" + month;
                }
                if (day < 10) {
                    day = "0" + day;
                }
                let nowDate = year + "-" + month + "-" + day;
                return nowDate
            },
            maxMinTemp() {
                return item => {
                    return `${ item.tempMin }~${ item.tempMax }℃`
                }
            },
            warnColor(){
                if(this.warnWeatherList){
                    let info = this.warnWeatherList
                    let color = config.warnWeatherColor[info.level] || '#fff'
                    return {
                        color
                    }
                }
                return ''
            },
            warnLevel(){
                if(this.warnWeatherList){
                    const levelInfo = {
                        '蓝色': 'Ⅳ级',
                        '黄色': 'Ⅲ级',
                        '橙色':'Ⅱ级',
                        '红色':'Ⅰ级'
                    }
                    let info = this.warnWeatherList
                    return levelInfo[info.level] || ''
                    
                }
                return ''
            },
            paramValueHandle(){
                 return item => {
                      if(item.paramVal !== 0 && !item.paramVal) return 0
                      if(item.paramName.includes('风向')){
                     
                        let valArr = [
                            {
                                max:112.5,
                                min:67.5,
                                name: '北风'
                            },
                            {
                                max:157.5,
                                min:112.5,
                                name: '西北风'
                            },
                            {
                                max:202.5,
                                min:157.5,
                                name: '西风'
                            },
                            {
                                max:247.5,
                                min:202.5,
                                name: '西南风'
                            },
                            {
                                max:292.5,
                                min:247.5,
                                name: '南风'
                            },
                            {
                                max:337.5,
                                min:292.5,
                                name: '东南风'
                            },
                            {
                                max:22.5,
                                min:337.5,
                                name: '东风'
                            },
                            {
                                max:67.5,
                                min:22.5,
                                name: '东北风'
                            }
                        ]
                        let val = parseInt(item.paramVal)
                        let resultName = '无风'
                   
                        valArr.some(item => {
                            
                            if(val > 337.5 || val <= 22.5){
                                resultName= '东风'
                                return true
                            } 
                            if(val> item.min && val<= item.max){
                                resultName= item.name
                                return true
                            }
                        })
                        return resultName
                   
                      }else {
                          return item.paramVal
                      }
                 }
            },
        },
        methods:{
            // 基地概况
            async reqEntBaseSurveyList() {
                const res = await this.$api.EntBaseSurveyList()
                if(res && res.length){
                     let totalArea = 0,fliterData = []
                     res.forEach(item => {
                          if(item.acreage > 0){
                            totalArea += item.acreage
                            fliterData.push({
                                value: item.acreage,
                                name: item.baseName
                            })
                          }
                     })
                     this.initEcharts('pieDome',optionIndexPie(fliterData))
                     this.totalArea = totalArea
                }
            }, 
            // 设备接入
            async reqIotDeviceAccessList() {
                const res =await this.$api.IotDeviceAccessList()
                if(res && res.length){
                   this.devOnlineInfo = res
                }
            },
            // 设备快照
            async reqIotDeviceSnapshotList() {
                 const res = await this.$api.IotDeviceSnapshotList()
                 if(res && res.length){
                      let notParam= {
                            paramIcon: '',
                            paramType: 'none',
                            paramName: '暂无数据'
                     }
                      res.forEach(item => {
                           if(item.deviceSnapshots){
                               
                               let dataLength = item.deviceSnapshots.length
                               if(dataLength < 7){
                                   let result = 7 - dataLength
                                   for(let i= 0; i < result; i++){
                                       item.deviceSnapshots.push(notParam)
                                   }
                               }else if(dataLength > 7){
                                  
                                   item.deviceSnapshots.splice(7)
                               }
                           }
                      })
                      this.snapshotsDevName = res[0].devName
                      this.snapshotsDevList = res
                      this.$nextTick( () => {
                            this.swiperSnapahot('.devSnapahot-swiper')
                      })
                 }
                

            },
            // 设备下拉
            async reqIotDeviceDropDownList() {
                 const res = await this.$api.IotDeviceDropDownList()
                 if(res && res.length){
                         this.devSelectOption= res
                         this.devId = res[0].id
                         this.devCode = res[0].tag
                         this.reqIotDeviceDataAnalysisLis()
                 }
                 
            },
            // 数据分析
            async reqIotDeviceDataAnalysisLis() {
                this.changLoading = true
                const res = await this.$api.IotDeviceDataAnalysisList({
                    analysisType: this.paramActive,
                    devId: this.devId,
                    devCode: this.devCode
                })
                setTimeout(()=> {
                   this.changLoading = false
                },300)
                
                if(res && res.length){
                   this.MaxMinParamList = res.filter(item => !item.paramName.includes('风向'))
                }else{
                   this.MaxMinParamList = []
                }
            },
            // 智能预警
            async reqLogIntelligentWarningList() {
                 const res = await this.$api.LogIntelligentWarningList()
                 this.tableData = res
             
            },
            // 获取天气城市
            async reqCompanyWeatherDetail() {
               const res = await this.$api.CompanyWeatherDetail()
               if(res){
                  let { lng,lat, distict} = res
                  this.reqQweatherLookup(distict)
                  let lngLat = lng && lat ? [lng,lat] : ''
                  this.reqMapShowData(lngLat) 
                  this.city = distict
               }
         
            },
            // 获取和风气象天气数据
            async reqQweatherLookup(city = '成都市'){
                 const res = await this.$api.QweatherLookup({ location : city})
                  if(res.code === '200' && res.location && res.location.length){
                      let infoData = res.location[0]
                        this.reqAllQweather(infoData.id)
                        this.admProvince = infoData.adm1
                        this.country = infoData.country
                  }

                   
            }, 
            // 请求地图展示数据
            reqMapShowData(lngLat){
                let arrWork = (data) => {
                    return new Promise((resolve, reject) => {
                       this.$api[data]().then((res) => {
                            resolve(res);
                        });
                    });
                };
                // 基地区域 和 点位信息
                let work1 = arrWork('EntAreaDeviceList');
                 // 获取基地信息
                let work2 = arrWork('EntBaseAreaInfo');

                Promise.all([work1, work2])
                .then((result) => {
                    this.mapInit(result,lngLat)
                    // this.newMapInit(result,lngLat)
                })
                .catch((error) => {});
            },
            
            waringTimeHanlde(row) {
                let date = row.alarmTime
                if(date){
                    let i= date.indexOf(' ')
                    return date.slice(0,i)
                }
            },
            devChange(val){
              
               this.devSelectOption.some( item => {
                       if(item.id === val){
                       
                           this.devCode = item.tag
                           return true
                       }                    
               })
               
              this.reqIotDeviceDataAnalysisLis()
            },
            reqAllQweather(location) {
                 let arrWork = (data) => {
               
                    return new Promise((resolve) => {
                        this.$api[data]({ location }).then(res => {

                            resolve(res)
                        });
                    })
                }
                let work1 = arrWork('QweatherNow')
                let work2 = arrWork('QweatherIndices')
                let work3 = arrWork('Qweather7d')
                let work4 = arrWork('QweatherWarning')
                Promise.all([work1, work2, work3, work4]).then((result) => {
                 
                    // let res1 = result[0],res2 = result[1],res3 = result[2]
                    let [res1,res2,res3,res4] = result
                    if(res1.code === '200'){
                       this.nowWeatherInfo = res1.now || []
                    }
                    if(res2.code === '200'){
                       this.airInfo = res2.daily[0] || []
                    }
                    if(res3.code === '200'){
                        this.sevenWeatherList = res3.daily || []
                    }
                    if(res4.code === '200'){
                        if(res4.warning && res4.warning.length)
                           this.warnWeatherList = res4.warning[0]
                   
                    }
                    this.$nextTick( () => {
                      this.swiperInfo('.devNub-swiper')
                    })
                    
                   
                })
            },
            weatherTypeChange(code) {
                this.weatherActive = code
                if(this.weatherSwiper) {
                 
                    this.weatherSwiper.slideTo(code,500,false);
                }
            },
            paramTypeChange(code) {
                this.paramActive = code
                this.reqIotDeviceDataAnalysisLis()
            },
            swiperInfo(dome) {
               const _that = this 
               let weatherSwiper =  new this.$Swiper(dome,{
                    on: {
                        slideChangeTransitionEnd: function(){
                          _that.weatherActive= this.activeIndex
                        },
                    }
               })  
               this.weatherSwiper = weatherSwiper
            },
            swiperSnapahot(dome) {
               const _that = this 
               let info= this.snapshotsDevList
               let infoLength = info.length
               let autoData = {delay: 5000, disableOnInteraction:false}
               let isAutoPlay = infoLength > 1 ? autoData : false
               
                
               let mySwiper = new this.$Swiper(dome,{
                    // loop: true,
                     grabCursor: true,//鼠标光标
          
                    setWrapperSize :true,//自动轮播
                    autoplay: isAutoPlay,
                    on: {
                        slideChangeTransitionEnd: function(){
                          let index = this.activeIndex
                        //   隐藏代码为loop为true时，修改index
                        //   let result = null
                        //   if(index <= infoLength){
                        //       result = index - 1
                        //   }else{
                        //       result = index%infoLength - 1
                        //   }
                          
                           _that.snapshotsDevName = info[index].devName
                        },
                    }
               })  
                     /*鼠标移入停止轮播，鼠标离开 继续轮播*/
                if(isAutoPlay){
                    mySwiper.el.onmouseover = function(){ //鼠标放上暂停轮播
                        mySwiper.autoplay.stop();
                    }
                    mySwiper.el.onmouseleave = function(){
                        mySwiper.autoplay.start();
                    }
                }

            },
            // 初始化echarts
            initEcharts(dome,option,loopTooltip= false) {
                
                let mychart = this.$echarts.init(this.$refs[dome]) 
                mychart.setOption( option ) 
                tools.loopShowTooltip(mychart,option,{loopSeries: false,interval: 3000})
                window.addEventListener("resize", function() {
                    mychart.resize();
                }); 
            },
            hanledXY(length){
                let lenX = length < 11? length:  length%10 
                let lenY = length < 11? 0 : Math.floor(length/10)
                let dataX = (19 + ( ( lenX - 1 ) *88))
                let dataY = (8 +  ( lenY *88))
                return [dataX, dataY]
            },
            // 获取ip定位信息
            getIpPosition(){
                AMap.plugin('AMap.Geolocation', function() {
                    new AMap.Geolocation({
                        // 是否使用高精度定位，默认：true
                        enableHighAccuracy: true,
                        // 设置定位超时时间，默认：无穷大
                        timeout: 10000,
                        // 定位按钮的停靠位置的偏移量
                        offset: [10, 20],
                        //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                        zoomToAccuracy: true,     
                        //  定位按钮的排放位置,  RB表示右下
                        position: 'RB'
                    })


                })
            },
            mapInit(dataList,lngLat){
              
                window.forceWebGL = true
                AMapLoader.load({
                    "key": "296001efb793b1e2631010fa1e8c0524",              // 申请好的Web端开发者Key，首次调用 load 时必填
                    "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                    "Loca":{                // 是否加载 Loca， 缺省不加载
                        "version": '2.0'  // Loca 版本，缺省 1.3.2
                    },
                }).then((AMap)=>{
                    
                        let [pointInfo, areaInfo] = dataList
                    
                        let polygonArr = [], layers= []
                        let featuresInfo= [], _that = this
                        lngLat = this.userInfo ? transform.gTw(this.userInfo.lng, this.userInfo.lat) : lngLat; 
                        let map = new AMap.Map('container', {
                            zoom: this.userInfo.mapLevel || 16,
                            pitch: 40,
                            viewMode: '3D',
                            center: lngLat,
                        });
                        if(!lngLat) this.getIpPosition() 
                        var vectorLayer = new AMap.VectorLayer();
                        map.add(vectorLayer)
                        if(pointInfo && pointInfo.length){
                            
                            pointInfo.forEach(item => {
                                // 区域绘制
                                if(item.geo){
                                    let path = JSON.parse(item.geo)
                                   
                                    const fillColor = item.mapColor || '#307AB3'

                                    let polygon = new AMap.Polygon({
                                                path: path,
                                                strokeColor: "#6DCDFF", 
                                                strokeWeight: 0,
                                                // strokeOpacity: 0.2,
                                                fillOpacity: 0.4,
                                                fillColor: fillColor,
                                                zIndex: 0,
                                                id: item.id,
                                                name: item.name,
                                                acreage: item
                                            })
                                    polygon.on('click', (e) => {
                                          
                                            let breathfeat =  isPoint(e) 
                                           
                                            if(breathfeat) initInfoWindow(breathfeat)           
                                    })
                                    vectorLayer.add(polygon)
                                    // polygon.show()
                                    polygonArr.push(polygon)
                                } 
                                // 点位绘制
                                if(item.points && item.points.length){
                                    
                                    item.points.forEach(val => {
                                        if(val.lat && val.lng ){
                                            
                                            let lnglats = this.isMapLayer ? transform.gTw(val.lng, val.lat) : [val.lng,val.lat];   
                                        
                                            let feature = {
                                                "type": "Feature",
                                                "properties": {
                                                    "devInfo": val
                                                },
                                                "geometry": {
                                                    "type": "Point",
                                                    "coordinates": lnglats
                                                }
                                            }
                                         
                                            featuresInfo.push(feature)
                                            let marker = new AMap.Marker({
                                                    anchor: 'bottom-center',
                                                    position: lnglats,
                                                    offset: new AMap.Pixel(0, -65),
                                                    content: `<p id="mapPoiontTextName"><span class="iconfont icon-zhuangshi-zuo"></span><span>${val.pointName}</span><span class= "iconfont icon-zhuangshi-you"></span></p>`
                                            })
                                            marker.setMap(map)

                                        }   
                                    
                                    })
                                }
                            })
                        }
                        if(areaInfo && areaInfo.length){
                            let areaPointIcon = require('@/assets/imgs/mapPiont/base.png')
                            areaInfo.map(item => {
                                let { lng, lat } = item
                                if(lng,lat){
                                     let lnglats = this.isMapLayer ? transform.gTw(lng, lat)  : [lng,lat]
                                  
                                
                                     let contentText = `<div id="mapAreaTextName"><p id="mapPoiontTextName"><span class="iconfont icon-zhuangshi-zuo"></span>` 
                                     contentText += `<span>${item.baseName}</span><span class= "iconfont icon-zhuangshi-you"></span></p>`
                                     contentText += `<img src="${ areaPointIcon }" alt=""></div>`
             
                                     let marker = new AMap.Marker({
                                            anchor: 'bottom-center',
                                            position: lnglats,
                                            offset: new AMap.Pixel(0,0),
                                            content:contentText
                                    })
                                    marker.setMap(map)
                                }
                               
                            })
                        } 

                        let geoData = {
                            "type": "FeatureCollection",
                            "features": featuresInfo,
                        }
                    
                        var loca = new Loca.Container({
                            map,
                        });
                       
                        var geo = new Loca.GeoJSONSource({
                            data: geoData,
                        });                    
                        var layer = new Loca.IconLayer({
                            zIndex: 140,
                            opacity: 1,
                        });
                        layer.setSource(geo);
                        
                        layer.setStyle({
                            unit: 'px',
                            icon: (i, f) => {
                                let { devInfo } = f.properties
                                let nub = devInfo && devInfo.devNum ? 
                                          devInfo.devNum < 9 ? devInfo.devNum : 8 : 0
                                let url = require('@/assets/imgs/mapPiont/'+ nub +'.png')
                                return url
                            },
                            iconSize: [60,60],
                            offset: [0, 30],
                            rotation: 0,
                        })
                        layers.push(layer)
                        loca.add(layer);
                        map.on('click', (e) => {
                            
                            let breathfeat =  isPoint(e) 
                          
                            if(breathfeat) initInfoWindow(breathfeat)
                            
                        });
                        function isPoint(e) {
                        
                            const allWidth= document.body.clientWidth
                            let times = 1920/allWidth 
                            let pixelarr = e.pixel.toArray()
                            let pixel = [pixelarr[0]*times,pixelarr[1]*times]
                            for(let i= 0; i < layers.length; i++){
                               
                                let ruselt = layers[i].queryFeature(pixel)
                              
                                if(ruselt) return ruselt    
                            }
                            
                        }
                        function initInfoWindow(breathfeat){
                            let [lng,lat] = breathfeat.coordinates
                            map.setCenter([lng,lat])
                            if(breathfeat.properties.devInfo.devNum > 0){
                                const $dome = windowInfo.call(this,{
                                    devId: breathfeat.properties.devInfo.id
                                })
                                //鼠标点击marker弹出自定义的信息窗体
                                let infoWindow = new AMap.InfoWindow({
                                    isCustom: true,  //使用自定义窗体  windowInfo(e.target.orderno.deviceDataInfos)
                                    content: $dome,
                                    closeWhenClickMap: true,
                                    // anchor: new AMap.Pixel(0,0),
                                    offset: new AMap.Pixel(0,-24)
                                })
                                        
                                infoWindow.open(map,[lng,lat] );
                            
                            }else{
                            
                                _that.$msg.info('当前点位暂无设备!')
                            }
                        }
                        if(this.isMapLayer){
                             let wms = new AMap.TileLayer.WMTS({
                                url: 'http://t4.tianditu.gov.cn/img_w/wmts',
                                blend:false,
                                titleSize: 256,
                                params: {
                                    Layer: 'img',
                                    Version: '1.0.0',
                                    Format: 'tiles',
                                    TileMatrixSet: 'w',
                                    STYLE: 'default',
                                    tk:'61697684f6ac5be217900661ff150609'
                                }
                            })
                            wms.setMap(map) 
                        }
                       
                        // map.add(polygonArr)
                }).catch(e => {
                    console.log(e);
                })
            },
         
            tableRowClassName() {
            
                return 'warning-row';
               
            },
            tableRow({rowIndex}){
                if(rowIndex%2 !== 0){
                 return 'table-row'
                }else{
                    return 'table-odd-row'
                }
            },
            handleEdit(){

            }

        }
    }
</script>

<style lang="scss" scoped>
   @mixin flex($center1: center, $center2: center) {
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: $center1; // flex-start | flex-end | center | space-between | space-around
        align-items: $center2; // flex-start | flex-end | center | baseline | stretch
   }
  .hn_indexHome_page_box{
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 15px 15px 0 15px;
      position: relative;
      .top_box{
          width: 100%;
          height: 67%;
          display: flex;
          .left_box{
              width: 25%;
              height: 100%;
              flex-shrink: 0;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-right: 10px;
              & > div {
                  width: 100%;
                  height: 49%;
              }
              .weather_box{
                 box-sizing: border-box;
                 padding: 10px;
                 & > p {
                     font-size: 12px;
                     color: #fff;
                     & > span:nth-child(2){
                        display: inline-block;
                        margin: 0 5px;
                     }
                     & > span:nth-child(4){
                        display: inline-block;
                        margin: 0 12px;
                     }
                 }
                 .weather_navList_box{
                     margin-top: 10px;
                    @include flex(center,center);
                    & > span{
                       color: #fff;
                       font-size: 18px;
                       flex-shrink: 0;
                    }
                    & > ul {
                       flex: 1;
                       display: flex;
                       height: 26px;
                       line-height: 26px;
                       margin-left: 12px;
                       font-size: 12px;
                       color: #fff;
                       border-radius: 13px;

                       & > li {
                           padding: 0 15px;
                           border-radius: 13px;
                           margin-right: 8px;
                       }
        
                    }
                 }
                 .devNub-swiper{
                     width: 100%;
                     height: calc(100% - 54px);
                     .slide_dome1{
                        & > div{
                             @include flex(center,center);
                             color: #fff;
                             .img_box{
                                 height: 106px;
                                 width: 128px;
                                 position: relative;
                                 overflow: hidden;
                                 & > img {
                                     width: 100%;
                                    //  height: 100%;
                                     position: absolute;
                                     top: -10px;
                                     left: 0;
                                 }
                             }
                             & > div:last-child{
                                 color: #fff;
                                 & > p:first-child{
                                     font-size: 24px;
                                 }
                                 & > p:last-child{
                                     font-size: 18px;
                                     margin-top: 10px;
                                     & > span:last-child{
                                          font-size: 14px;
                                          display: inline-block;
                                          padding: 2px 15px;
                                          border-radius: 15px;
                                          margin-left: 10px;
                                     }
                                 }
                             }
                        }
                        & > p {
                            width: 100%;
                            color: #fff;
                            font-size: 12px;
                            text-align: center;
                        }
                        & > ul {
                           width: 100%;
                           height: 56px;
                           margin-top: 10px;
                           border-radius: 8px;
                           display: flex;
                           justify-content: space-around;
                           & > li {
                               display: flex;
                               align-items: center;
                               color: #fff;
                               font-size: 12px;
                               & > img {
                                   width: 30px;
                                   height: 30px;
                                   margin-right: 5px;
                               }

                               & > p {
                                   display: flex;
                                   flex-direction: column;
                                   & > span:first-child{
                                       margin-bottom: 5px;
                                   }
                               }
                           }
                        }
                     }
                     .slide_dome2{
                         & > ul {
                             height: 100%;
                             display: flex;
                             align-items: center;
                            //  padding: 18px 0;
                             box-sizing: border-box;
                             & > li {
                                flex: 1;
                                 text-align: center; 
                                 font-size: 12px;
                                 & > p:nth-child(3){
                                     font-size: 18px;
                                    
                                     margin-top: 20px;
                                 } 
                                  
                                 & > p:nth-child(5){
                                 
                                     margin-top: 20px;
                                      color:  #00C395;
                                     margin-bottom: 3px;
                                 } 
                                 & > p:nth-child(6){
                                     font-size: 12px;
                                     width: 44px;
                                     margin: 0 auto;
                                     padding: 3px 0;
                                     border-radius: 5px;
                                 } 
                             }
                         }
                     }
                     .slide_dome3{
                         .warnWeather_box{
                             padding: 8px;
                             box-sizing: border-box;
                             & > div:first-child{
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                  padding: 12px 0 14px 0;
                                  & > span{
                                      font-size: 60px;
                                      margin-right: 18px;
                                  }
                                  & > div{
                                      font-size: 24px;
                                      & > p {
                                       
                                      }
                                      & > p:last-child {
                                           font-size: 18px;
                                           margin-top: 8px;
                                           & > span:last-child{
                                               display: inline-block;
                                              margin-left: 12px;
                                              font-size: 14px;
                                              padding: 3px 16px;
                                              border-radius: 18px;
                                           }
                                      }
                                  }
                             }
                             & > div:nth-child(2){
                                 font-size: 14px;
                                 text-align: center;
                             }
                             & > div:last-child{
                                 width: 100%;
                                 margin-top: 10px;
                                 height: 64px;
                                 padding: 0 10px;
                                 border-radius: 5px;
                                 box-sizing: border-box;
                                 display: flex;
                                 justify-content: center;
                                 align-items: center;
                                 & > span{
                                    font-size: 34px;
                                    flex-shrink: 0;   
                                 }
                                 & > div{
                                     flex: 1;
                                    //  margin-left: 20px;
                                     width: 100%;
                                     overflow: hidden;
                                     font-size: 14px;
                                     & > p:first-child{
                                        margin-left: 20px; 
                                        
                                     }
                                     & > p:last-child{
                                         margin-top: 3px;
                                        //  padding-left: 20px;
                                     }
                                 }
                             }
                         }
                     }
                 }
              }
              & > div:last-child{
                  .baseInfoPosition{
                      position: relative;
                      & > div:first-child{
                          width: 176px;
                          height: 176px;
                          position: absolute;
                          left: 50%;
                          margin-left: -87px;
                          top: 50%;
                          margin-top: -87px;
                          animation:turn 3s linear infinite;
                      }
                      .basetotleArea_box{
                          position: absolute;
                          left: 50%;
                          top: 50%;
                          transform:translate(-50%,-50%);
                          text-align: center;
                          color: #fff;
                          font-size: 14px;
                          & > p:last-child{
                              font-size: 18px;
                          }
                      }
                  }
              }
          }
          .right_box{
              width: 75%;
              height: 100%;
              flex: 1;
          }
      }
      .bottom_box{
          width: 100%;
          height: 33%;
          display: flex;
          box-sizing: border-box;
          padding-top: 10px;
          .subItem_content_box{
            height: 100%;
            .devList_warp_box{
                box-sizing: border-box;
                padding: 20px;
                 position: relative;
                .devInfoList_box{
                    box-sizing: border-box;
                    padding: 0 12px;
                    & > li {
                        display: flex;
                        align-items: center;
                        color: #fff;
                        font-size: 12px;
                        & > span:first-child{
                            display: block;
                            width: 30px;
                            height: 5px;
                            border-radius: 30%;
                            margin-right: 12px;
                        }
                        & > span:nth-child(2){
                            
                            font-size: 14px;
                            width: 100px;
                            text-align: center;
                            border-radius: 5px;
                            line-height: 22px;
                        }
                        & > div {
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                            & > p {
                                display: flex;
                                align-items: center;
                            }
                            & > p > span:first-child{
                                display: block;
                               width: 8px;
                               height: 8px;
                               border-radius: 4px;
                               margin-right: 6px;
                            }
                           
                            & > p:nth-child(2){
                                & > span:first-child{
                                    background: #ffa800;
                                }
                                & > span:last-child{
                                  color: #ffa800;
                              
                                }
                            } 
                            & > p:nth-child(3){
                                & > span:first-child{
                                    background: #ff5400;
                                }
                                & > span:last-child{
                                  color: #ff5400;
                               }
                            } 
                        } 
                       
                    }
                    & > li:not(:first-child){
                        margin-top: 18px;
                    }

                }
            }
            .paramName_box{
                 font-size: 14px; 
                 margin-right: 10px;
            }
            .dev_param_box{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                padding: 10px 42px;
                .left_box{
                   width: 127px;
                   height: 100%;
                   position: relative;
                   & > p:first-child{
                       font-family: 'agencyr';
                       font-size: 14px;
                       text-align: center;
                       width: 100%;
                       position: absolute;
                       left: 0;
                       top: 26%;
                       & > span{
                            color: #ffa800;
                            font-size: 28px;  
                            font-weight: bold;  
                       }
                   }
                   & > p:last-child{
                       position: absolute;
                       left: 5%;
                       top: 78%;
                   }
                }
                .right_box{
                   width: 215px;
                   height: 207px;
                   position: relative;
                   .snapshots_box {
                       width: 68px;
                       height: 79px;
                       .snapshots_boxs{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            font-size: 12px;
                            & > span:first-child{
                                font-size: 28px;
                            }
                       }
                       
                       
                   }
                  
                   .snapshots_box:nth-child(1){
                       position: absolute;
                       left: 36px;
                       top: 0;
                   }
                   .snapshots_box:nth-child(2){
                       position: absolute;
                       left: 110px;
                       top: 0;
                   }
                   .snapshots_box:nth-child(3){
                       position: absolute;
                       left: 0;
                       top: 65px;
                   }
                   .snapshots_box:nth-child(4){
                       position: absolute;
                       left: 74px;
                       top: 65px;
                   }
                   .snapshots_box:nth-child(5){
                       position: absolute;
                       left: 148px;
                       top: 65px;
                   }
                   .snapshots_box:nth-child(6){
                       position: absolute;
                       bottom: 0;
                       left: 36px;
                   }
                   .snapshots_box:nth-child(7){
                       position: absolute;
                       bottom: 0;
                       left: 110px;
                   }
                }
            }
            .data_analysis_box{
                  box-sizing: border-box;
                  padding: 15px;
                  & > ul {
                       display: flex;
                       width: 214px;
                       height: 26px;
                       margin: 0 auto;
                       line-height: 26px;
                       font-size: 12px;
                       color: #fff;
                       border-radius: 13px;

                       & > li {
                           padding: 0 15px;
                           border-radius: 13px;
                           
                       }
                       & > li:not(:last-child){
                          margin-right: 8px;
                       }
        
                  }
                  .param_val_box{
                      width: 100%;
                      height: calc(100% - 43px);
                      box-sizing: border-box;
                      padding-top: 15px;
                      & > ul {
                         display: flex;
                         flex-wrap: wrap;
                         & > li {
                           width: 49%;
                           height: 50%;
                           box-sizing: border-box;
                           display: flex;
                           align-items: center;
                           justify-content: center;
                           padding-left: 20px;
                           & > div:first-child{
                             width: 46px;
                             height: 46px;
                             flex-shrink: 0;
                             position: relative;
                             & > span:first-child{
                                 display: block;
                                 width: 100%;
                                 height: 100%;
                                 animation:turn 3s linear infinite;
                             }
                             & > span:last-child{
                                 font-size: 28px;
                                 position: absolute;
                                 left: 50%;
                                 top: 50%;
                                 transform:translate(-50%,-50%)
                             }
                           }
                           & > div:last-child{
                               flex: 1;
                               height: 100%;
                               margin-left: 10px;
                               font-size: 12px;
                               display: flex;
                               flex-direction: column;
                               justify-content: center;
                               & > p {
                                   margin-bottom: 4px;
                               }
                               .maxColor{
                                  color: #ffa800;
                               }
                           }
                         }
                      }
                      
                     
                  }
            }
            .devSelect_box{
                margin-right: 10px;
                margin-top: 3px;
                width: 150px;
            }
            .opira_but_box{
                color: #fff;
                height: 26px;
                border: none;
                border-radius: 8px;
                // line-height: 28px;

            }
            .opira_but_box:hover{
               opacity: .8;
               background: #00C395;
            }
            .warn_box{
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 8px 0 8px 0;
            }
          }
          .subItem_content_box:first-child{
              width: 25%;
              flex-shrink: 0;
          }
          .subItem_content_box:not(:first-child){
              flex: 1;
          }
          .subItem_content_box:not(:last-child){
              margin-right: 10px; 
          }
      }
    //   @keyframes turn{
    //     0%{-webkit-transform:rotate(0deg);}
    //     25%{-webkit-transform:rotate(90deg);}
    //     50%{-webkit-transform:rotate(180deg);}
    //     75%{-webkit-transform:rotate(270deg);}
    //     100%{-webkit-transform:rotate(360deg);}
    //   }
  }
   .textAnimate {
        // padding-left: 20px;
        font-size: 12px;
        display: inline-block;
        white-space: nowrap;
        animation: 40s wordsLoop linear infinite normal;
    }

    @keyframes wordsLoop {
        0% {
            transform: translateX(200px);
            -webkit-transform: translateX(200px);
        }
        100% {
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
        }
    }

    @-webkit-keyframes wordsLoop {
        0% {
            transform: translateX(200px);
            -webkit-transform: translateX(200px);
        }
        100% {
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
        }
    }
  .font-icon-svg {
    width: 38px;
    height: 38px;
    fill: currentColor;
    overflow: hidden;
  }
  .el-table{
    background: rgba(0,0,0,0);
    color: #fff;
    &::before{
        height: 0; 
    }
  }
  .el-table ::v-deep .warning-row{
       background: linear-gradient(
            to right, 
            rgba(0,0,0,0) 0%, 
            rgba(255,144,0,0.4) 50%, 
            rgba(0,0,0,0) 100%);
      & > th{
        // background: rgba(255,144,0,0.2);
         background: rgba(0,0,0,0);
        height: 28px;
        padding: 5px 0;
        color: #fff;
      }
            
  }
   .el-table ::v-deep td.el-table__cell{
       border-bottom: none;
   }
  .el-table ::v-deep th.el-table__cell.is-leaf{
      border-bottom: none;
  }
  .el-table ::v-deep .table-odd-row {
      background: rgba(0,0,0,0);
    //   & > td.el-table__cell{
    //       border-bottom: none;
    //   }
  }
  .el-table ::v-deep .el-table__body-wrapper {
      background: rgba(0,0,0,0) !important;
  }
  .el-table ::v-deep tbody tr:hover>td {
    background-color: rgba(0,0,0,0)!important
  }

.el-table ::v-deep tbody tr:hover {
    background-color: rgba(0,0,0,0)!important
}

</style>
<template>
      <div class="paramDome_main_box" :style="varStyle">
       <div class="top_box">
           <div>
             <span class="iconfont icon-title baseColor"></span>
             <span>{{ devName }}</span>
             <!-- <span class="iconfont icon-xinhao baseColor"></span> -->
           </div>
           <span class="el-icon-close cp" @click="colseClick"></span>
           
       </div>
       <div class="group_box">
           <!-- <p>
               <span>分组：</span>
               <span>{{ groupName }}</span>
           </p> -->
           <p>
               <span>序列号：</span>
               <span>{{ devCode }}</span>
           </p>
       </div>
       <div class="param_content_box">
           <template v-if="isOnline"> 
             <slot></slot>
           </template> 
           <template v-else>
              <div :class="['offLine', 'wh100', onlineStatus == 2 ?'standby': !onlineStatus ? 'off' :'']">
                  <span class="iconfont icon-caijiqi pr"></span>
                  <p>{{ onlineStatus == 2 ?'设备待机': !onlineStatus ? '设备离线' :'' }}</p>
              </div>
           </template>
       </div> 
      
    </div>
</template>

<script>
    import config from '@/config'
    export default {
        props:{
            dataList:{
                type: Object,
            },
            width:{
                type: String,
                default: '380px'
            },
            minHeight:{
                type: String,
                default: '150px'
            }
        },
        data(){
            return{
               onlineData: config.devStatus.online
            }
        },
        computed:{

            varStyle(){
               return {
                   '--width': this.width,
                   '--minHeight': this.minHeight
               }
            },
            devName(){
              let info = this.dataList
              return info && info.devName ? info.devName : ''   
            },
            groupName(){
                let info = this.dataList
                return info && info.groupName ? info.groupName : ''   
            },
            devCode(){
                let info = this.dataList
                return info && info.devCode ? info.devCode : ''   
            },
            isOnline(){
                let info = this.dataList
                return info && info.onlineStatus == this.onlineData ? true : false
            },
            onlineStatus(){
                return  this.dataList.onlineStatus || ''
            },
           
        },
        methods:{
            colseClick(){
               this.$emit('onclose')
            },

        }
    }
</script>

<style lang="scss" scoped>
.paramDome_main_box{
    width: var(--width);
    .top_box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > div{
            display: flex;
            align-items: center;
            font-size: 16px;
            & > span:nth-child(1){
                font-size: 24px;
                margin-right: 2px;
            }
            & > span:nth-child(3){
                margin-left: 6px;
            }
        }
        & > span {
            font-size: 18px;
        }
    }
    .group_box{
       display: flex;
       justify-content: space-between; 
       font-size: 14px;
       & > p:first-child{
              text-indent: 25px;
       }
    }
    .param_content_box{
        max-height: 300px;
        min-height: var('--minHeight');
        overflow-y: auto;
        position: relative;
        .offLine{
           width: 100%;
           height: 150px;
           display: flex;
           flex-direction: column;
           justify-content: center;
           align-items: center;
           font-size: 16px;
           
           & > span {
               font-size: 60px;
           }
           & > span::after {
               content: '';
               width: 40px;
               height: 40px;
               border-radius: 100%;
               position: absolute;
               left: -5px;
               top: 0px;
           }
           
        }
       .off{
          color: #999;
        }
        .off >span::after{
            background: rgba($color: #868585, $alpha: 0.3);
        }
        .standby{
          color: #FFA800;
        }
        .standby >span::after{
            background: rgba($color: #FFA800, $alpha: 0.3);
        }
    }
}
</style>
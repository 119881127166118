<template>
<div class="windowInfoMainBox" ref="winInfo">
   
    <circles :devList='devList'></circles>
        
</div>

</template>

<script>
    import circles from '@/components/localComponent/circle.vue'
    export default {
        name:'windowInfo',
        components:{
            circles
        },
        props:{
            devId: String
        },
        watch:{
          devId:{
              handler(val){
                this.reqIotDevPointDeviceList()
              }
          }
        },
        data(){
          return{
             devList: []
           
                
             
          }
        },
        methods:{
             async reqIotDevPointDeviceList(){
                const res = await this.$api.IotDevPointDeviceList({
                    id: this.devId
                })
                
                if(res && res.length){
                    res.forEach(item => {
                        item.visible = false
                    })
                    this.devList= res
                }
               
            }
        }
        
      
 
    }
</script>

 <style lang="scss" scoped>
.windowInfoMainBox{
    position: relative;
    width: 5px;
    height: 5px;
    position: relative;
 
 }
</style>
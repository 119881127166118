<template>
    <div class="box_main_box boxBg">
       <div class="top_box index-title-bg-image">
           <div>
               <img :src="iconUrl"></img>
               <span>{{ title }}</span>
           </div>
           <div>
               <slot name="tools"></slot>
           </div>
       </div>
       <div class="box_content_box">
           <slot></slot>
       </div>
    </div>
</template>

<script>
    export default {
        props:{
            title: String
        },
        data() {
            return{
               iconUrl: require('@/assets/imgs/index/indexDefault/title_icon.png')
            }
        }
    }
</script>

<style lang="scss" scoped>
.box_main_box{
    width: 100%;
    height: 100%;
    .top_box{
       height: 28px;
       display: flex;
       justify-content: space-between;
       align-items: center;
       color: #fff;
       & > div:first-child{
          padding-left: 20px;
          font-size: 15px;
          display: flex;
          align-items: center;
          & > img{
              margin-right: 15px;
          }
          
       }
    }
    .box_content_box{
        width: 100%;
        height: calc(100% - 28px);
    }
}
</style>